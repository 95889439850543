import React from 'react';
import { IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonSpinner, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import './Home.css';
import {
  cloudOfflineOutline,
  partlySunnyOutline,
  sunnyOutline
} from "ionicons/icons";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFirstLoading: true,
      ips: [],
      toastMessage: null,
      toastError: false,
    }

    this.reloadIPs();

    this.onMessageClicked = this.onMessageClicked.bind(this);

    // window.document.addEventListener('visibilitychange', () => {
    //   if (!window.document.hidden) {
    //     console.log('Visibility changed');
    //     this.setState({ isLoading: true });
    //     this.reloadMessages();
    //   }
    // });
  }

  ApiUrl = 'https://ip.podogov.com';
  UserId = 'alexey';
  Key = '4A32F1B9-6C22-4038-B223-05A9858C16C1';

  formatRelativeDateTime(now, date) {
    let text;
    const diff = Math.round((now - date) / 1000);

    let d = diff;
    if (d <= 60) {
      text = `${d} second(s) ago`;
    } else {
      d = Math.round(d / 60);
      if (d <= 60) {
        text = `${d} minute(s) ago`;
      } else {
        d = Math.round(d / 60);
        if (d <= 24) {
          text = `${d} hour(s) ago`;
        } else {
    
        text = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        }
      }
    }

    return { text, diff };
  }

  async reloadIPs() {
    const response = await fetch(`${this.ApiUrl}/read?userId=${this.UserId}&key=${this.Key}`);
    const ips = await response.json();
    if (!ips) {
      this.setState({
        toastMessage: 'Failed to load data :(',
        toastError: true,
      });
      return;
    }

    ips.sort((a, b) => {
      if (a.description < b.description) return -1;
      if (a.description > b.description) return 1;
      return 0;
    });

    const now = new Date();

    for (const m of ips) {
      const lastSyncTime = new Date(m.lastSyncTime);

      let { text, diff } = this.formatRelativeDateTime(now, lastSyncTime);

      m.lastSyncTimeText = text;
      m.outdated = diff > 60 * 60 * 2;
      m.veryOutdated = diff > 60 * 60 * 24 * 30;
      
      const lastChangeTime = new Date(m.lastChangeTime);
      ({ text } = this.formatRelativeDateTime(now, lastChangeTime));
      m.lastChangeTimeText = text;
    }

    console.log(ips);

    this.setState({
      isLoading: false,
      isFirstLoading: false,
      ips: ips,
    });
  }

  async onMessageClicked(message) {
    try {
      console.log(message);
      await navigator.clipboard.writeText(message.ip);
      this.setState({
        toastMessage: 'Copied to clipboard.',
        toastError: false,
      });
    } catch {
      this.setState({
        toastMessage: `Failed to copy.`,
        toastError: true,
      });
    }
  }

  render() {

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='secondary'>
            <IonTitle>IPs</IonTitle>
            <IonButtons slot='end'>
              {this.state.isLoading && <IonSpinner name='dots' color='light' />}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {this.state.isFirstLoading
            ? <IonTitle><IonSpinner /></IonTitle>
            : (
              <IonList inset='true'>
                {this.state.ips.map(m =>
                  <IonItem key={m.hostName} button='true' detail='false' onClick={() => this.onMessageClicked(m)}>
                    <IonIcon slot='start'
                      icon={m.veryOutdated ? cloudOfflineOutline : m.outdated ? partlySunnyOutline : sunnyOutline}
                      color={m.veryOutdated ? 'danger' : m.outdated ? 'secondary' : 'warning'} />
                    <IonLabel color={m.veryOutdated ? 'medium' : ''}>
                      <IonText><strong>{m.description}</strong></IonText>
                      {(m.lastChangeTime !== m.lastSyncTime) && <IonNote><p>Synced {m.lastSyncTimeText}</p></IonNote>}
                      <IonNote><p>Changed {m.lastChangeTimeText}</p></IonNote>
                    </IonLabel>
                    <IonLabel slot='end'>{m.ip}</IonLabel>
                    {/*<IonButtons slot='end'>*/}
                    {/*  <IonButton>*/}
                    {/*    <IonIcon slot='icon-only' icon={informationCircleOutline}*/}
                    {/*             color={m.read ? 'medium' : m.new ? 'primary' : 'dark'} />*/}
                    {/*  </IonButton>*/}
                    {/*</IonButtons>*/}
                  </IonItem>
                )}
              </IonList>
            )}
        </IonContent>

        <IonToast isOpen={!!this.state.toastMessage} message={this.state.toastMessage}
          position='top'
          duration={1500} color={this.state.toastError ? 'danger' : 'success'}
          onDidDismiss={() => this.setState({ toastMessage: null })} />
      </IonPage>
    );
  }
}